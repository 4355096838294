/*
 * @Description: 
 * @Author: 琢磨先生
 * @Date: 2022-05-08 16:53:52
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-05-08 16:55:27
 */
import http from './axios_init';


export default {

    /**
     * 获取应用的菜单和api数据
     * @param {*} appkey 
     * @returns 
     */
    get_funs(appkey){
        return http.get(`/admin/v1/function?appkey=${appkey}`);
    }
    
}